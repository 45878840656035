const sample = [
  {
    id: 0,
    created_at: "1 Jan 2021",
    title: "Test Presentation",
    presentation: [
      `<p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 1</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>This slide was inserted here for editing</strong></span></p>
        <p></p>`,
    ],
  },
  {
    id: 1,
    created_at: "14 Jan 2021",
    title: "Gibberish Presentation",
    presentation: [
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 1</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>These are nothing but gibberish slides</strong></span></p>
        <p></p>
          `,
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 2</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 48px;"><strong>This is all gibberish</strong></span></p>
        <p></p>
        `,
    ],
  },
  {
    id: 2,
    created_at: "14 Jan 2021",
    title: "Beta Slides",
    presentation: [
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 1</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>These are nothing but gibberish slides</strong></span></p>
        <p></p>
          `,
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 2</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 48px;"><strong>This is all gibberish</strong></span></p>
        <p></p>
        `,
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 3</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 48px;"><strong>Chaa gaye beta</strong></span></p>
        <p></p>
        `,
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 4</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 48px;"><strong>Aray betay moj kara diiiii</strong></span></p>
        <p></p>
        `,
      `
        <p style="text-align: center;"><span style="font-size: 36pt;"><strong>Slide 5</strong></span></p>
        <p style="text-align: center;"><span style="font-size: 48px;"><strong>Happy ending</strong></span></p>
        <p></p>
        `,
    ],
  },
];

export default sample;
