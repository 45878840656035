import "./AppHeader.css";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Button, Input } from "antd";
import {
  FolderOpenOutlined,
  SaveOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  modalIsVisible_dispatch,
  previewVisible_action,
} from "./AppHeader.logic";
import PresentationList from "../PresentationList/PresentationList";
import Preview from "../Preview/Preview";
import { setTitle_action } from "../PresentationList/PresentationList.logic";
import { galaxyRed, particles } from "../Themes/galaxyRed";

const AppHeader = ({
  modalIsVisible_dispatch,
  previewVisible_action,
  titleText,
}) => {
  const { Header } = Layout;
  const [title, setTitle] = useState("untitled-presentation");

  const previewPresentation = () => {
    try {
      var output = null;
      var previewOutput = null;
      document.getElementById("preview").innerHTML = "";
      var iframe = document.getElementById("preview");
      iframe = iframe.contentWindow;
      console.log(iframe);
      var steps = document.getElementsByClassName("slides");
      for (var i = 0; i < steps.length; i++) {
        previewOutput += steps[i].innerHTML;
        console.log(output);
      }
      let resultf1 = previewOutput.replaceAll("&gt;", ">");
      let resultf2 = resultf1.replaceAll("&lt;", "<");
      let resultf3 = resultf2.replaceAll("&nbsp;", " ");
      let resultf4 = resultf3.replaceAll("undefined", "");
      previewOutput = `<!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="utf-8" />
          <title>${title}</title>
          ${galaxyRed}
        </head>
        <body class="impress-not-supported" id="particles-js" onwheel="onWheelSpin()">
          <div id="impress" data-transition-duration="1000">
            ${resultf4}
          </div>
          <div id="impress-toolbar"></div>
          <div class="impress-progressbar"><div></div></div>
          <div class="impress-progress"></div>
          <div id="impress-help"></div>
          <script src="https://cdn.jsdelivr.net/gh/bartaz/impress.js@1.1.0/js/impress.js"></script>
          <script>
            impress().init();
          </script>
          ${particles}
        </body>
      </html>
      `;
      iframe.document.open();
      iframe.document.write(previewOutput);
      iframe.focus();
    } catch (err) {
      window.alert("Create at least one slide to preview");
    }
  };

  useEffect(() => {
    setTitle(titleText);
  }, [titleText]);

  return (
    <Header className="header-container white">
      <div className="btn-group">
        <Button
          type="primary"
          icon={<FolderOpenOutlined style={{ fontSize: "16px" }} />}
          className="btn"
          onClick={() => modalIsVisible_dispatch()}
        >
          Open
        </Button>
        <Button
          type="primary"
          icon={<SaveOutlined style={{ fontSize: "16px" }} />}
          className="btn"
        >
          Save
        </Button>
        <Button
          type="primary"
          icon={<EyeOutlined style={{ fontSize: "16px" }} />}
          className="btn"
          onClick={() => {
            previewVisible_action();
            previewPresentation();
          }}
        >
          Preview
        </Button>
      </div>
      <div className="input-container">
        <Input
          style={{ fontSize: "16px" }}
          placeholder="Presentation Title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <PresentationList />
      <Preview />
    </Header>
  );
};

const mapStateToProps = (state) => {
  return {
    titleText: state.presentationList_reducer.title,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modalIsVisible_dispatch: () => dispatch(modalIsVisible_dispatch()),
    previewVisible_action: () => dispatch(previewVisible_action()),
    setTitle_action: () => dispatch(setTitle_action()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
