import "./Slide.css";

import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Masonry from "react-responsive-masonry";
import { Modal } from "antd";
import { images } from "./images";

const Slide = ({ content }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textArea, setTextArea] = useState();
  const [editorContent, setEditorContent] = useState("");
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [z, setZ] = useState(0);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);

  useEffect(async () => {
    setX(Math.floor(Math.random() * 5000));
    setY(Math.floor(Math.random() * 5000));
    setZ(Math.floor(Math.random() * 5000));
    setRotateX(Math.floor(Math.random() * 20));
    setRotateY(Math.floor(Math.random() * 90));
    setRotateZ(Math.floor(Math.random() * 20));
  }, []);

  useEffect(() => {
    console.log("Coming from SLIDE: " + content);
    if (textArea !== undefined) {
      textArea.setContent("");
      textArea.insertContent(content);
      setEditorContent(content);
    } else console.log(content);
  }, [textArea, content]);

  const showImagesModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const config = {
    height: 450,
    menubar: false,
    media_live_embeds: true,
    setup: (editor) => {
      editor.on("init", () => {
        setTextArea(editor);
      });
      editor.ui.registry.addButton("addImage", {
        icon: "image",
        onAction: () => {
          showImagesModal();
        },
      });
    },
    fontsize_formats:
      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
    plugins: [
      "advlist autolink lists link image charmap print preview anchor media",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime table paste code emoticons highcharts highchartssvg noneditable",
    ],
    toolbar:
      "undo redo | fontselect | fontsizeselect | bold italic underline forecolor backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist | addImage media link emoticons ",
  };

  return (
    <div className="editor-container">
      <Modal
        title="Select Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[]}
        width={1000}
      >
        <div className="image-gallery">
          <Masonry columnsCount={3} gutter="10px">
            {images.map((image, i) => (
              <img
                key={i}
                src={image}
                style={{ width: "100%", display: "block" }}
                onClick={() => {
                  textArea.insertContent(`<img src="${image}" /> &nbsp;`);
                  handleOk();
                }}
              />
            ))}
          </Masonry>
        </div>
      </Modal>
      <Editor
        apiKey="362nrjwkydark4wc7dqp71kfll7kpdvwlvw9mspi5s8tkia7"
        init={config}
        onChange={(e) => {
          setEditorContent(e.target.getContent());
        }}
      />
      <div className="slides">
        <div
          className="step"
          data-x={x}
          data-y={y}
          data-z={z}
          data-scale="3"
          data-rotate-x={rotateX}
          data-rotate-y={rotateY}
          data-rotate-z={rotateZ}
        >
          {editorContent.replaceAll("&nbsp;", "\n")}
        </div>
      </div>
    </div>
  );
};

export default Slide;
