import sample from "../../sample";

import axios from "axios";

const PREVIEW_VISIBLE = "PREVIEW_VISIBLE";
const SET_TITLE = "SET_TITLE";
const SET_CONTENT = "SET_CONTENT";

const initialState = {
  isVisible: false,
  title: "",
  content: [""],
};

export const previewVisible_action = () => {
  return {
    type: PREVIEW_VISIBLE,
  };
};

export const setTitle_action = (data) => {
  return {
    type: SET_TITLE,
    payload: data,
  };
};

export const setContent_action = (data) => {
  return {
    type: SET_CONTENT,
    payload: data,
  };
};

export const openPresentation = (id) => {
  return async (dispatch) => {
    dispatch(setTitle_action(sample[id].title));
    dispatch(setContent_action(sample[id].presentation));
  };
};

const presentationList_reducer = (state = initialState, action) => {
  switch (action.type) {
    case PREVIEW_VISIBLE:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    default:
      return state;
  }
};

export default presentationList_reducer;
