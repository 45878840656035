import "./PresentationList.css";

import React from "react";
import { Modal, List, Input } from "antd";
import { connect } from "react-redux";
import { modalVisible_action } from "../AppHeader/AppHeader.logic";
import { openPresentation } from "./PresentationList.logic";

const PresentationList = ({
  modalIsVisible,
  modalVisible_action,
  data,
  openPresentation,
}) => {
  const { Search } = Input;
  return (
    <Modal
      footer={null}
      title="Open Presentation"
      visible={modalIsVisible}
      onOk={() => modalVisible_action()}
      onCancel={() => modalVisible_action()}
    >
      <Search
        placeholder="Search..."
        enterButton
        style={{ marginBottom: "10px" }}
      />
      <div className="list">
        <List
          size="large"
          bordered
          dataSource={data}
          className="list"
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    onClick={() => {
                      openPresentation(item.id);
                      modalVisible_action();
                    }}
                  >
                    {item.title}
                  </a>
                }
                description={`Created On : ${item.created_at}`}
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modalIsVisible: state.modalIsVisible_reducer.isVisible,
    data: state.modalIsVisible_reducer.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    modalVisible_action: () => dispatch(modalVisible_action()),
    openPresentation: (id) => dispatch(openPresentation(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PresentationList);
