export const images = [
  "https://picsum.photos/200/300?image=1050",
  "https://picsum.photos/300/300?image=206",
  "https://i.imgflip.com/4v1xcf.jpg",
  "https://i.imgflip.com/4umivv.jpg",
  "https://i.imgflip.com/4v1v2x.jpg",
  "https://i.imgflip.com/4v0bca.jpg",
  "https://i.imgflip.com/4v0spz.jpg",
  "https://i.imgflip.com/4unp90.jpg",
  "https://thumbor.granitemedia.com/money-is-money/6W8k0GsV4O6_UiRUp32NWAJCOf8=/480x0/filters:quality(80)/granite-web-prod/bf/01/bf013673bb254c8f964fbf8190e507e3.jpeg",
  "https://www.memesmonkey.com/images/memesmonkey/4b/4b58411940ea0f732a206250dd636713.jpeg",
  "https://static.boredpanda.com/blog/wp-content/uploads/2019/06/funny-government-memes-30.jpg",
  "https://www.dontworkanotherday.com/wp-content/uploads/2020/04/Money-Memes-2.jpg",
];
