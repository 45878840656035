import "./App.css";

import React from "react";
import { Layout } from "antd";
import SlidesTab from "./SlidesTab/SlidesTab";
import AppHeader from "./AppHeader/AppHeader";

const App = () => {
  const { Content } = Layout;

  return (
    <Layout className="body white">
      <AppHeader />
      <Content className="content white">
        <SlidesTab />
      </Content>
      <p className="version">v2.0.0</p>
    </Layout>
  );
};

export default App;
