import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import modalIsVisible_reducer from "../components/AppHeader/AppHeader.logic";
import presentationList_reducer from "../components/PresentationList/PresentationList.logic";

const appReducer = combineReducers({
  modalIsVisible_reducer: modalIsVisible_reducer,
  presentationList_reducer: presentationList_reducer,
});
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
