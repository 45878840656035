import "./Preview.css";
import { CloseCircleOutlined } from "@ant-design/icons";

import React from "react";
import { connect } from "react-redux";
import { previewVisible_action } from "../AppHeader/AppHeader.logic";

const Preview = ({ previewVisible, previewVisible_action }) => {
  return (
    <>
      <CloseCircleOutlined
        onClick={() => {
          console.log(previewVisible);
          previewVisible_action();
        }}
        style={{
          color: "#a4b0be",
          fontSize: "14px",
          zIndex: previewVisible ? 10 : -100,
          opacity: previewVisible ? 1 : 0,
          position: "fixed",
          top: "10px",
          left: "10px",
        }}
      />
      <iframe
        visible={previewVisible}
        id="preview"
        style={{
          position: "absolute",
          height: previewVisible ? "100%" : "0px",
          width: previewVisible ? "100%" : "0px",
          zIndex: previewVisible ? 9 : -100,
          opacity: previewVisible ? 1 : 0,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    previewVisible: state.modalIsVisible_reducer.previewVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    previewVisible_action: () => dispatch(previewVisible_action()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
