import "./SlidesTab.css";

import React, { useEffect, useState } from "react";
import { Tabs, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Slide from "../Slide/Slide";
import { connect } from "react-redux";

const { TabPane } = Tabs;

const SlidesTab = ({ slideContent }) => {
  let initialPanes = [
    {
      title: "Slide 1",
      content: <Slide content={`${slideContent}`} />,
      key: "0",
      closable: false,
    },
  ];

  const [panes, setPanes] = useState(initialPanes);
  const [activeKey, setActiveKey] = useState(initialPanes[0].key);
  const [newTabIndex, setNewTabIndex] = useState(0);

  useEffect(() => {
    setPanes([]);
    setNewTabIndex(0);
    let sn = 1;
    const newPanes = [];
    slideContent.map((n) => {
      newPanes.push({
        title: `Slide ${sn}`,
        content: <Slide content={n} />,
        key: n,
      });
      sn += 1;
      setActiveKey(n);
    });
    setNewTabIndex(sn - 2);
    setPanes(newPanes);
  }, [slideContent]);

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  const add = () => {
    const activeKey = `newTab${newTabIndex}`;
    const newPanes = [...panes];
    newPanes.push({
      title: `Slide ${newTabIndex + 2}`,
      content: <Slide content={``} />,
      key: activeKey,
    });
    setPanes(newPanes);
    setActiveKey(activeKey);
    setNewTabIndex(newTabIndex + 1);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex;
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = panes.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setPanes(newPanes);
    setActiveKey(newActiveKey);
  };

  return (
    <>
      <Tabs
        hideAdd
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={remove}
        tabPosition={"left"}
        style={{ height: 450 }}
      >
        {panes.map((pane) => (
          <TabPane
            className="tabs-sidebar"
            tab={pane.title}
            key={pane.key}
            closable={pane.closable}
          >
            {pane.content}
          </TabPane>
        ))}
      </Tabs>

      <Button
        className="btn-add"
        type="primary"
        shape="circle"
        icon={<PlusOutlined style={{ fontSize: "18px" }} />}
        onClick={add}
      ></Button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    slideContent: state.presentationList_reducer.content,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SlidesTab);
