import background from "../../assets/img/341174290_Rawpixel.com.jpeg";

export const galaxyRed = `
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

b,
strong {
  font-weight: 900;
}

i,
em {
  font-style: italic;
}

a {
  color: #ff7454;
  text-decoration: none;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #ff3607;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  text-align: center;
  color: #ffffff;
  letter-spacing: -1px;
  padding: 1em;
}

h1 {
  font-size: 4.5em;
  color: #ffffff;
  margin: auto;
}

h2 {
  font-size: 3em;
  color: #ffffff;
  padding: 0.5em;
}

h3 {
  font-size: 2.3em;
}

h4 {
  font-size: 2em;
}

p {
  font-size: 1.7em;
  text-align: left;
  padding-bottom: 5%;
}

ol,
ul {
  counter-reset: li;
  margin-left: 5%;
  padding-left: 0;
}

ol > li,
ul > li {
  position: relative;
  margin: 7% 2em 0 3em;
  padding: 8px 8px;
  list-style: none;
  border-top: 2px dashed #666;
  font-size: 1.7em;
  text-align: left;
}

ol > li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: -2px;
  left: -2em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 2em;
  margin-right: 8px;
  padding: 4px;
  padding-top: 8px;
  border-top: 2px dashed #666;
  border-right: 2px dashed #666;
  font-weight: bold;
  text-align: center;
}

ul > li:before {
  content: "\\0BB \\020";
  position: absolute;
  top: -2px;
  left: -2em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 2em;
  margin-right: 8px;
  padding: 4px;
  border-top: 2px dashed #666;
  font-weight: bold;
  text-align: center;
}

li ol,
li ul {
  margin-top: 10px;
}

ol ol li:last-child {
  margin-bottom: 0;
}

blockquote {
  font-size: 1.8em;
  font-style: italic;
  width: 80%;
  margin: 0.25em 0;
  padding: 7% 0 0 15%;
  line-height: 1.9em;
  position: relative;
  color: #ffffff;
}

blockquote:before {
  font-family: "Source Sans Pro";
  display: block;
  content: "\\201C";
  font-size: 6em;
  position: absolute;
  left: -3%;
  top: 10%;
  color: #ffffff;
}

blockquote cite {
  color: #ffffff;
  font-size: 0.8em;
  display: block;
  margin-top: 3px;
  text-align: right;
}

blockquote cite:before {
  content: "\\2014 \\2009";
}

img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: auto;
}

.step {
  position: relative;
  width: 900px;
  padding: 40px;
  margin: 20px auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.impress-enabled .step {
  margin: 0;
  opacity: 0.05;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.impress-enabled .step.active {
  opacity: 1;
}

.slide {
  display: block;
  width: 600px;
  height: 600px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-image: url("../img/blank55.png");
}

.slide-circle {
  width: 700px;
  height: 700px;
  padding: 110px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background: url("../img/blank44.png");
  border: 2px dashed rgba(0, 0, 0, 0.3);
}

.impress-enabled .hint {
  display: block;
}

.impress-on-bored .hint {
  opacity: 1;

  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);

  -webkit-transition: opacity 1s 5s, -webkit-transform 0.5s 4.5s;
  -moz-transition: opacity 1s 5s, -moz-transform 0.5s 4.5s;
  -ms-transition: opacity 1s 5s, -ms-transform 0.5s 4.5s;
  -o-transition: opacity 1s 5s, -o-transform 0.5s 4.5s;
  transition: opacity 1s 5s, transform 0.5s 4.5s;
}

.impress-enabled {
  pointer-events: none;
}

.impress-enabled #impress {
  pointer-events: auto;
}

#impress .step .substep {
  opacity: 0;
}

#impress .step .substep.substep-visible {
  opacity: 1;
  transition: opacity 1s;
}
.notes {
  display: none;
}

.impress-enabled div#impress-toolbar {
  position: fixed;
  right: 1px;
  bottom: 1px;
  opacity: 0.6;
  z-index: 10;
}
.impress-enabled div#impress-toolbar > span {
  margin-right: 10px;
}
.impress-enabled div#impress-toolbar.impress-toolbar-show {
  display: block;
}
.impress-enabled div#impress-toolbar.impress-toolbar-hide {
  display: none;
}

.impress-progress {
  position: absolute;
  left: 59px;
  bottom: 1px;
  text-align: left;
  font-size: 10pt;
  opacity: 0.6;
}
.impress-enabled .impress-progressbar {
  position: absolute;
  right: 318px;
  bottom: 1px;
  left: 118px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.impress-progressbar {
  right: 118px;
}
.impress-progressbar DIV {
  width: 0;
  height: 2px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.6);
  transition: width 1s linear;
}
.impress-enabled .impress-progress {
  position: absolute;
  left: 59px;
  bottom: 1px;
  text-align: left;
  opacity: 0.6;
  color: white;
}
.impress-enabled #impress-help {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
  color: #eeeeee;
  font-size: 80%;
  position: fixed;
  left: 2em;
  bottom: 2em;
  width: 24em;
  border-radius: 1em;
  padding: 1em;
  text-align: center;
  z-index: 100;
  font-family: Verdana, Arial, Sans;
}
.impress-enabled #impress-help td {
  padding-left: 1em;
  padding-right: 1em;
}
body.impress-mouse-timeout div#impress-toolbar {
  display: none;
}

body.impress-mouse-timeout {
  cursor: none;
}

.impress-enabled {
  pointer-events: none;
}
.impress-enabled #impress {
  pointer-events: auto;
}

.impress-enabled #impress-toolbar {
  pointer-events: auto;
}
.impress-enabled #impress-console-button {
  pointer-events: auto;
}
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("${background}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
</style>
`;

export const particles = `
<script src='https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js'></script>
    <script>
        particlesJS("particles-js", {
            "particles": {
                "number": {
                    "value": 100,
                    "density": {
                        "enable": true,
                        "value_area": 800
                    }
                },
                "color": {
                    "value": "#ffffff"
                },
                "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 1,
                        "color": "#4d7796"
                    },
                },
                "opacity": {
                    "value": 0.5,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                    }
                },
                "size": {
                    "value": 1,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "size_min": 0.1,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 100,
                    "color": "#4d7796",
                    "opacity": 0,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "left",
                    "random": true,
                    "straight": true,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "retina_detect": true
        });
    </script>
`;
