import sample from "../../sample";
import axios from "axios";

const MODAL_VISIBLE = "MODAL_VISIBLE";
const PREVIEW_VISIBLE = "PREVIEW_VISIBLE";

const initialState = {
  isVisible: false,
  previewVisible: false,
  data: [],
  title: "untitled-presentation",
};

export const modalVisible_action = (data) => {
  return {
    type: MODAL_VISIBLE,
    payload: data,
  };
};

export const previewVisible_action = () => {
  return {
    type: PREVIEW_VISIBLE,
  };
};

export const modalIsVisible_dispatch = () => {
  return (dispatch) => {
    dispatch(modalVisible_action(sample));
  };
};

const modalIsVisible_reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_VISIBLE:
      return {
        isVisible: !state.isVisible,
        data: action.payload,
      };
    case PREVIEW_VISIBLE:
      return {
        ...state,
        previewVisible: !state.previewVisible,
      };
    default:
      return state;
  }
};

export default modalIsVisible_reducer;
